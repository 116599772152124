import Glide from '@glidejs/glide'

window.addEventListener("DOMContentLoaded", function () {
	if (document.querySelector("#js-plyr-player")) {
		// console.log("initializing work video player")
		const vw = getViewWidth()
		if (vw >= 768) {
			var controls = ['play', 'progress', 'current-time', 'fullscreen']
		} else {
			var controls = ['play-large', 'progress', 'current-time', 'fullscreen']
		}
		window.player = new Plyr("#js-plyr-player", {
			controls: controls,
			clickToPlay: true,
			invertTime: false,
			quality: { default: 1080, options: [1080, 720, 576, 480] },
			fullscreen: { enabled: true, fallback: true, iosNative: true },
			iconUrl: "/images/plyr.svg",
			keyboard: { focused: true, global: true },
		})
		window.player.on('ready', event => {
			// const instance = event.detail.plyr;
			if (document.body.classList.contains("works-show")) {
				// More Works 的作品滑鼠或手指滑過會觸發影片預覽播放
				document.querySelectorAll(".js-more-works__item").forEach(function (el) {
					el.addEventListener("mouseenter", function (e) {
						var theVideoTag = e.target.querySelector("video")
						var theVideoSource = theVideoTag.querySelector("source")
						if (theVideoSource) {
							if (theVideoSource.src != theVideoSource.getAttribute('data-video-url')) {
								theVideoSource.src = theVideoSource.getAttribute('data-video-url')
								theVideoTag.load()
								theVideoTag.onloadeddata = function() {
									theVideoTag.play()
								}
							} else {
								if (theVideoTag) {
									theVideoTag.play()
								} else {
									e.target.parentNode.querySelector("video").play()
								}
							}
						}
						// console.log("mouseenter")
					})
					el.addEventListener("touchstart", function(e) {
						if (e.target.querySelector("video")) {
							var theVideoTag = e.target.querySelector("video")
						} else {
							var theVideoTag = e.target.parentNode.querySelector("video")
						}
						var theVideoSource = theVideoTag.querySelector("source")
						if (theVideoSource) {
							if (theVideoSource.src != theVideoSource.getAttribute('data-video-url')) {
								theVideoSource.src = theVideoSource.getAttribute('data-video-url')
								theVideoTag.load()
								theVideoTag.onloadeddata = function() {
									theVideoTag.play()
								}
							} else {
								if (theVideoTag) {
									theVideoTag.play()
								} else {
									e.target.parentNode.querySelector("video").play()
								}
							}
						}
						// console.log("enter")
					})
					el.addEventListener("mouseleave", function (e) {
						if (e.target.querySelector("video")) {
							e.target.querySelector("video").pause()
						} else {
							e.target.parentNode.querySelector("video").pause()
						}
						// console.log("leave")
					})
				})
			}
		});
		// 行動版才需要控制大顆暫停按鈕
		if (vw < 768) {
			window.player.on("controlshidden pause", function (e) {
				document.querySelector(".js-pause-large").style.display = "none"
			})
			window.player.on("controlsshown", function (e) {
				document.querySelector(".js-pause-large").style.display = "initial"
			})
		} else {
			// 桌面版 Safari 不支援 :fullscreen css，所以需要手動設定全螢幕
			if (window.player.fullscreen.enabled) {
				window.player.on("enterfullscreen", function(e) {
					e.target.style.width = "100vw"
					e.target.style.maxWidth = "100vw"
				})

				window.player.on("exitfullscreen", function(e) {
					e.target.style.width = null
					e.target.style.maxWidth = null
				})
			}
		}
	}
})

function getViewWidth() {
	return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

document.addEventListener("DOMContentLoaded", function () {
	if (document.body.classList.contains("works-show")) {
		if (document.querySelector(".glide")) {
			var glide = new Glide('.glide', {
				type: 'carousel',
				perView: 4,
				focusAt: 2,
				gap: 0,
				breakpoints: {
					768: {
						perView: 2.3,
						focusAt: 'center',
						swipeThreshold: 40,
					},
					414: {
						perView: 2,
						focusAt: 'center',
						swipeThreshold: 40,
					}
				}
			})

			glide.mount()
		}
	}
})