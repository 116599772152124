window.addEventListener("load", function () {
  // 以下為全部 about 動態
  if (document.body.classList.contains('pages-about')) {
    if (document.querySelector('.aboutus-svg')) animateCSS(document.querySelector('.aboutus-svg'), 'p-fadeInLeft');
    if (document.querySelector('.pages-about__hero > i')) document.querySelector('.pages-about__hero > i').style.transform = 'skewX(0deg) translateX(100%)';

    // init rellax
    const rellax = new Rellax('.js-rellax');
    // init scroll-out
    ScrollOut({
      onShown: handleOnShown,
      onHidden: handleOnHidden
    });

    function handleOnShown(el) {
      el.style.animationDelay = '700ms';
      el.classList.add('animated', 'p-revealInUp');
    }
    function handleOnHidden(el) {
      el.style.opacity = 0;
    }
  }
  function animateCSS(node, animationName, callback) {
    node.classList.add('animated', animationName)

    function handleAnimationEnd() {
      node.classList.remove('animated', animationName)
      node.removeEventListener('animationend', handleAnimationEnd)

      if (typeof callback === 'function') callback()
    }

    node.addEventListener('animationend', handleAnimationEnd)
  }


});