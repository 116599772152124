document.addEventListener("DOMContentLoaded", function () {
	if (document.body.classList.contains("works-index")) {
		jsAutoplay()
		
		document.querySelectorAll(".js-work").forEach(function (el) {
			el.addEventListener("mouseenter", function (e) {
				var theVideoTag = e.target.querySelector("video")
				var theVideoSource = theVideoTag.querySelector("source")
				if (theVideoSource) {
					if (theVideoSource.src != theVideoSource.getAttribute('data-video-url')) {
						theVideoSource.src = theVideoSource.getAttribute('data-video-url')
						theVideoTag.load()
						theVideoTag.onloadeddata = function() {
							theVideoTag.play()
						}
					} else {
						if (theVideoTag) {
							theVideoTag.play()
						} else {
							e.target.parentNode.querySelector("video").play()
						}
					}
				}
				// console.log("mouseenter")
			})
			el.addEventListener("touchstart", function(e) {
				if (e.target.querySelector("video")) {
					var theVideoTag = e.target.querySelector("video")
				} else {
					var theVideoTag = e.target.parentNode.querySelector("video")
				}
				var theVideoSource = theVideoTag.querySelector("source")
				if (theVideoSource) {
					if (theVideoSource.src != theVideoSource.getAttribute('data-video-url')) {
						theVideoSource.src = theVideoSource.getAttribute('data-video-url')
						theVideoTag.load()
						theVideoTag.onloadeddata = function() {
							theVideoTag.play()
						}
					} else {
						if (theVideoTag) {
							theVideoTag.play()
						} else {
							e.target.parentNode.querySelector("video").play()
						}
					}
				}
				// console.log("enter")
			})
			el.addEventListener("mouseleave", function (e) {
				if (e.target.querySelector("video")) {
					e.target.querySelector("video").pause()
				} else {
					e.target.parentNode.querySelector("video").pause()
				}
				// console.log("leave")
			})
		})
	}
})