window.addEventListener("load", function () {
  // contact page 動態
  if (document.body.classList.contains('pages-contact')) {

    const person = document.querySelector('.pages-contact__person');
    const contents = document.querySelectorAll('.info__content');
    const domains = document.querySelectorAll('.info__domain');

    const contactMotion = {
      info: 'fadeIn',
      person: 'fadeInUp',
      contents: 'p-fadeInUp',
      domains: 'p-fadeInUp'
    }

    animateCSS(person, contactMotion.person);
    // console.log(contents);
    contents[0].style.animationDelay = '.4s';
    contents[1].style.animationDelay = '.7s';
    contents[3].style.animationDelay = '1s';
    contents.forEach(element => {
      element.style.animationDuration = '2s';
      animateCSS(element, contactMotion.contents);
    });
    domains.forEach(element => {
      element.style.animationDuration = '2s';
      element.style.animationDelay = '.7s';
      animateCSS(element, contactMotion.domains);
    });

    document.querySelector('.js-layer').style.transform = 'translateX(100%) skewX(0deg)';
  }

  function animateCSS(node, animationName, callback) {
    node.classList.add('animated', animationName)

    function handleAnimationEnd() {
      node.classList.remove('animated', animationName)
      node.removeEventListener('animationend', handleAnimationEnd)

      if (typeof callback === 'function') callback()
    }

    node.addEventListener('animationend', handleAnimationEnd)
  }

});